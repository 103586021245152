<template>
  <div class="player-simulation-root">
    <PlayerSimulationByTeam :team-label="'teamA'" />
    <div class="divider" />
    <PlayerSimulationByTeam :team-label="'teamB'" />
  </div>
</template>

<script>
import PlayerSimulationByTeam from './PlayerSimulationByTeam';

export default {
  components: {
    PlayerSimulationByTeam,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
.player-simulation-root {
  display: flex;
  gap: 33px;
  position: relative;
  height: 100%;

  .divider {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background-color: $gray400;
  }
}
</style>
