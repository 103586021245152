<template>
  <div class="player-simulation-by-team-root">
    <div class="player-simulation-by-team-header">
      <h6>{{ teamName }}</h6>
      <TextInput
        :model-value="searchValue"
        :placeholder="'Search players'"
        :icon="'search'"
        small
        @update:modelValue="onSearch"
      />
    </div>
    <ParamsTabs
      class="batters-pitchers-tabs"
      :tabs="battersPitchersTabs"
      :active-tab="activeTab"
      @onTabChange="onTabChange"
    />
    <div class="player-simulation-by-team-table-root">
      <Table
        :columns="tableColumns"
        :rows="teamProbabilitiesByTabSelectedFiltered"
        :no-data-message="searchValue ? 'No players by active search' : 'No available probabilites'"
      >
        <template #cell="{ column, row }">
          <TableCell
            :column-key="column.key"
            :row="row"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  map, reduce, includes, lowerCase, filter,
} from 'lodash';
import ParamsTabs from '@/components/player-setup/ParamsTabs';
import Table from '@/components/common/Table';
import TextInput from '@/components/common/TextInput';
import TableCell from './table-config/TableCell';
import { playerSimulationHitterTableConfig, playerSimulationPitcherTableConfig } from './table-config/table-config';

export default {
  props: {
    teamLabel: {
      type: String,
      required: true,
    },
  },
  components: {
    ParamsTabs,
    Table,
    TableCell,
    TextInput,
  },
  setup(props) {
    const store = useStore();
    const battersPitchersTabs = computed(() => [
      {
        key: 'pitchers',
        label: 'Pitchers',
      },
      {
        key: 'hitters',
        label: 'Hitters',
      },
    ]);
    const activeTab = ref(battersPitchersTabs.value[0]);
    const onTabChange = (tab) => {
      activeTab.value = tab;
    };
    const searchValue = ref('');
    const playerSetupData = computed(() => store.getters.playerSetupData);
    const teamName = computed(() => playerSetupData.value[props.teamLabel]?.name);
    const teamProbabilities = computed(() => playerSetupData.value[props.teamLabel]?.probabilities);
    const allProbabilities = computed(() => {
      const pitchersProbabilities = map(teamProbabilities.value?.pitchersProbabilities, (player, key) => ({
        playerIndex: key,
        isPitcherProb: true,
        ...player,
      }));
      const hittersProbabilities = map(teamProbabilities.value?.battersProbabilities, (player, key) => ({
        playerIndex: key,
        isHitterProb: true,
        ...player,
      }));
      return [
        ...pitchersProbabilities,
        ...hittersProbabilities,
      ];
    });
    const teamProbabilitiesByTabSelected = computed(() => {
      if (activeTab.value.key === 'pitchers') {
        return filter(allProbabilities.value, (player) => player.isPitcherProb);
      }
      if (activeTab.value.key === 'hitters') {
        return filter(allProbabilities.value, (player) => player.isHitterProb);
      }
      return [];
    });
    const teamProbabilitiesByTabSelectedFiltered = computed(() => filter(teamProbabilitiesByTabSelected.value, (player) => includes(lowerCase(player.name), lowerCase(searchValue.value))));
    const tableColumns = computed(() => {
      let columns = [];
      if (activeTab.value.key === 'pitchers') {
        columns = playerSimulationPitcherTableConfig();
      } else if (activeTab.value.key === 'hitters') {
        columns = playerSimulationHitterTableConfig();
      }

      return reduce(
        columns,
        (fields, values, key) => [
          ...fields,
          {
            ...values,
            key,
          },
        ],
        [],
      );
    });

    const onSearch = (value) => {
      searchValue.value = value;
    };
    return {
      teamName,
      battersPitchersTabs,
      activeTab,
      tableColumns,
      searchValue,
      teamProbabilitiesByTabSelectedFiltered,
      onSearch,
      onTabChange,
    };
  },
};
</script>

<style lang="scss">
.player-simulation-by-team-root {
  width: 50%;
  .batters-pitchers-tabs {
    padding-left: 0;
    padding-right: 0;
  }
  .player-simulation-by-team-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 0 8px;
  }
  .player-simulation-by-team-table-root {
    height: calc(100% - 36px - 48px);
    .table-scroller {
      height: 100%;
    }
  }
}
</style>
