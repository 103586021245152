export const playerSimulationHitterTableConfig = () => ({
  name: {
    label: 'Player name',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 200,
    nonResizable: true,
  },
  playerHandedness: {
    label: 'H',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Handedness',
  },
  probToBeThePlayerAtBatInRestOfTheMatch: {
    label: 'AtBat',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Percentage of the time a player will be the one hitting',
  },
  probToAppearInRestOfMatch: {
    label: 'AP',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Appearance percentage',
  },
});

export const playerSimulationPitcherTableConfig = () => ({
  name: {
    label: 'Player name',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 200,
    nonResizable: true,
  },
  playerHandedness: {
    label: 'H',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Handedness',
  },
  pitches: {
    label: 'PC',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Pitch count',
  },
  battersFacedInRestOfTheMatch: {
    label: 'BF',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Batters faced',
  },
  probToAppearInRestOfMatch: {
    label: 'AP',
    visible: true,
    order: 4,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'Appearance percentage',
  },
  probToBeTheNextReliefPitcherInTeam: {
    label: '1stRP',
    visible: true,
    order: 5,
    filterable: false,
    sortable: false,
    nonResizable: true,
    tooltipText: 'First relief pitcher percentage',
  },
});
