<template>
  <span
    class="table-label-cell"
    v-html="value"
  />
</template>

<script>
import { computed } from 'vue';
import { isNil } from 'lodash';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    function formatNumber(num) {
      return num.toFixed(1) % 1 === 0 ? num.toFixed(0) : num.toFixed(1);
    }
    const value = computed(() => {
      const { columnKey, row } = props;
      const cellValue = row[columnKey];
      if (!isNil(cellValue)) {
        if (columnKey === 'name') {
          return formatPlayerName(cellValue);
        }
        if (columnKey === 'playerHandedness') {
          return cellValue === 'RIGHT' ? 'R' : 'L';
        }
        if (columnKey === 'pitches' || columnKey === 'battersFacedInRestOfTheMatch') {
          return formatNumber(cellValue);
        }
        if (
          columnKey === 'probToAppearInRestOfMatch'
          || columnKey === 'probToBeTheNextReliefPitcherInTeam'
          || columnKey === 'probToBeThePlayerAtBatInRestOfTheMatch') {
          return `${formatNumber(cellValue) * 100 }%`;
        }
        return cellValue;
      }
      return '-';
    });

    return {
      value,
    };
  },
};
</script>
<style lang="scss">
  .table-label-cell {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
